import { SpaVersion } from '@local/types'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import * as React from 'react'
import { CommitMsg } from './CommitMsg'

type Props = Pick<
  SpaVersion,
  'spaId' | 'commitAuthor' | 'commitDate' | 'commitMsg'
>

export function GitLogDetails({
  spaId,
  commitAuthor,
  commitDate,
  commitMsg
}: Props) {
  return (
    <section aria-label='Git log details'>
      <div>
        <span className='font-semibold'>Author: </span>
        {commitAuthor}
      </div>
      {commitDate && (
        <div>
          <span className='font-semibold'>Date: </span>
          {format(new Date(commitDate), Formats.dateTime.medium_with_seconds)}
        </div>
      )}
      {commitMsg && (
        <div className='mt-2'>
          <CommitMsg spaId={spaId} commitMsg={commitMsg} />
        </div>
      )}
    </section>
  )
}
