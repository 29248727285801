import * as React from 'react'
import { EnvElevation } from '@local/types'
import { ElevationCard } from './ElevationCard'
import {
  EditElevationModal,
  RemoveElevationModal,
  RemoveFromGroupModal
} from '../Modals'
import { useModal } from '../Modals'
import { sortByEnv } from './helpers'

type Props = {
  spaName: string
  entries: EnvElevation[]
  groupName: string
}

export function ElevationsGroup({ spaName, entries, groupName }: Props) {
  const { isOpen: isEditOpen, close: closeEdit, open: openEdit } = useModal()
  const {
    isOpen: isRemoveOpen,
    close: closeRemove,
    open: openRemove
  } = useModal()
  const {
    isOpen: isRemoveFromElevationOpen,
    close: closeRemoveElevation,
    open: openRemoveElevation
  } = useModal()
  const [selectedEntry, setSelectedEntry] = React.useState<EnvElevation | null>(
    null
  )

  return (
    <section role='contentinfo' aria-label={groupName}>
      <div className='px-4 pt-5 pb-3 mb-4 font-semibold border-b-2 type-headline-4 md:px-0'>
        {groupName}
      </div>
      <ul className='grid max-w-4xl grid-cols-1 gap-4 md:grid-cols-3'>
        {[...entries].sort(sortByEnv).map((entry, index) => (
          <li key={`${groupName}-${entry.environment}-${index}`}>
            <ElevationCard
              entry={entry}
              onEdit={() => {
                setSelectedEntry(entry)
                openEdit()
              }}
              onRemove={() => {
                setSelectedEntry(entry)
                openRemove()
              }}
              onRemoveElevation={() => {
                setSelectedEntry(entry)
                openRemoveElevation()
              }}
            />
          </li>
        ))}
      </ul>
      {selectedEntry && (
        <EditElevationModal
          spaName={spaName}
          groupName={groupName}
          isOpen={isEditOpen}
          close={closeEdit}
          entry={selectedEntry}
        />
      )}
      {selectedEntry && (
        <RemoveFromGroupModal
          spaName={spaName}
          groupName={groupName}
          isOpen={isRemoveOpen}
          close={closeRemove}
          entry={selectedEntry}
        />
      )}
      {selectedEntry && (
        <RemoveElevationModal
          spaName={spaName}
          groupName={groupName}
          isOpen={isRemoveFromElevationOpen}
          close={closeRemoveElevation}
          entry={selectedEntry}
        />
      )}
    </section>
  )
}
