import * as React from 'react'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { CardContainer } from '@toasttab/buffet-pui-card'

export function ElevationsLoading() {
  return (
    <div className='w-full'>
      <div className='w-full pt-2 pb-4 mb-4 space-y-6 border-b-2 px-4 md:px-0'>
        {/* Group header */}
        <Skeleton className='w-40 h-6' />
        {/* Tabs */}
        <Skeleton className='w-40 h-6' />
      </div>
      <div className='grid max-w-4xl grid-cols-1 gap-4 md:grid-cols-3'>
        <CardLoading />
        <CardLoading />
        <CardLoading />
      </div>
    </div>
  )
}

function CardLoading() {
  return (
    <CardContainer noPadding className='py-4 pb-4'>
      <div className='px-4'>
        <div className='flex justify-between mb-8 space-x-4 md:mb-8'>
          <Skeleton className='w-40' />
          <Skeleton className='w-6 h-6' />
        </div>
        <div className='w-1/2 space-y-4'>
          <Skeleton className='h-4' />
          <Skeleton className='h-5' />
          <Skeleton className='h-4' />
          <Skeleton className='h-4' />
        </div>
        <div className='w-32 mt-6'>
          <Skeleton className='h-12 md:h-10' />
        </div>
      </div>
    </CardContainer>
  )
}
