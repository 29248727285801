import * as React from 'react'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ApolloCache, gql, useMutation } from '@apollo/client'
import {
  MutationRemoveSpaElevationArgs,
  RemoveSpaGroupElevationMutation
} from '@local/types'
import { EnvElevation } from '@local/types'
import { Modal } from './Modal'
import { GET_SPA_ELEVATIONS, SpaElevationsResponse } from '../shared'
import { useSpaControlSnackbar } from './useSpaControlSnackbar'

type Props = {
  entry: EnvElevation
  spaName: string
  groupName: string
  isOpen: boolean
  close: () => void
}

export function RemoveFromGroupModal({
  spaName,
  groupName,
  isOpen,
  close,
  entry
}: Props) {
  const [confirmText, setConfirmText] = React.useState('')
  const [removeElevation, { loading }] = useRemoveFromGroup({
    onSuccess: onClose,
    onError: onClose
  })

  function onClose() {
    setConfirmText('')
    close()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>Remove from group</Modal.Header>
      <Modal.Body className='space-y-4'>
        <ReadonlyText label='Spa' value={spaName} />
        <ReadonlyText label='Group' value={groupName} />
        <ReadonlyText label='Environment' value={entry.environment} />
        <p className='type-default text-warning'>
          This SPA will be removed from the SPA group and environment
        </p>
        <TextInput
          label={
            <span className='font-normal'>
              Please type <strong>{spaName}</strong> to confirm
            </span>
          }
          name='confirmText'
          value={confirmText}
          onChange={(e) => setConfirmText(e.currentTarget.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='link' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='destructive'
          onClick={() => {
            removeElevation({
              variables: {
                spaId: spaName,
                group: groupName,
                environment: entry.environment
              }
            })
          }}
          disabled={confirmText !== spaName || loading}
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const REMOVE_SPA_GROUP_ELEVATION = gql`
  mutation RemoveSpaGroupElevation(
    $spaId: ID!
    $group: ID!
    $environment: Environment!
  ) {
    removeSpaElevation(
      spaId: $spaId
      group: $group
      environment: $environment
    ) {
      spaId
      group
      environment
    }
  }
`

type RemoveElevationProps = {
  onSuccess: () => void
  onError: () => void
}

function useRemoveFromGroup({ onSuccess, onError }: RemoveElevationProps) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSpaControlSnackbar()

  const result = useMutation<
    RemoveSpaGroupElevationMutation,
    MutationRemoveSpaElevationArgs
  >(REMOVE_SPA_GROUP_ELEVATION, {
    onCompleted: ({ removeSpaElevation }) => {
      onSuccess()
      showSuccessSnackBar(removeSpaElevation, 'removal')
    },
    onError: (err) => {
      onError()
      showErrorSnackBar(err)
    },
    refetchQueries: ['SpaEvents'],
    update: (cache, { data }) => {
      if (data) {
        removeSpaElevationFromCache(cache, data)
      }
    }
  })
  return result
}

function removeSpaElevationFromCache(
  cache: ApolloCache<any>,
  data: RemoveSpaGroupElevationMutation
) {
  const { spaId, environment, group } = data.removeSpaElevation
  const queryOptions = {
    query: GET_SPA_ELEVATIONS,
    variables: {
      spaId
    }
  }
  const existingGroupsData =
    cache.readQuery<SpaElevationsResponse>(queryOptions)
  if (existingGroupsData) {
    cache.writeQuery({
      ...queryOptions,
      data: {
        spaElevations: existingGroupsData.spaElevations.filter(
          (spaElevation) =>
            !(
              spaElevation.group === group &&
              spaElevation.environment === environment
            )
        )
      }
    })
    cache.gc()
  }
}
