import * as React from 'react'
import {
  Modal as BuffetModal,
  ModalProps as BuffetModalProps
} from '@toasttab/buffet-pui-modal'

function Modal(props: React.PropsWithChildren<BuffetModalProps>) {
  return <BuffetModal size='sm' {...props} />
}

Modal.Header = BuffetModal.Header
Modal.Body = BuffetModal.Body
Modal.Footer = BuffetModal.Footer

export * from '@toasttab/buffet-pui-modal'
export { Modal }
