import { ApolloError } from '@apollo/client'
import * as React from 'react'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'

export function useSpaControlSnackbar() {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  return {
    showSuccessSnackBar: (
      {
        spaId,
        group,
        environment
      }: {
        spaId: string
        group: string
        environment: string
      },
      type: 'elevation' | 'removal'
    ) => {
      if (type === 'elevation') {
        showSuccessSnackBar(
          `Successfully elevated SPA ${spaId} in group ${group} in environment ${environment}`
        )
      } else {
        showSuccessSnackBar(
          `Successfully removed SPA ${spaId} from group ${group} in environment ${environment}`
        )
      }
    },
    showErrorSnackBar: (error: ApolloError) => {
      console.error(error)
      if (error.graphQLErrors[0].extensions.response?.status === 403) {
        showErrorSnackBar(
          <p>
            You do not have the permission to edit elevations. Please request
            spa-control permission on service now:{' '}
            <a
              className='inline-link-light'
              rel='noopener noreferrer'
              target='_blank'
              href='https://toastinc.service-now.com/sp?id=sc_cat_item&sys_id=a5746b6d87dcc950193f41d8cebb3568&sysparm_category=d0c04e72dbf89410f8a2d6fa4b961964'
            >
              Request SPA Control Permission
              <LaunchIcon
                size='xs'
                className='ml-1 mb-1 align-middle'
                accessibility='decorative'
              />
            </a>
          </p>
        )
      } else {
        showErrorSnackBar(
          `An error occurred when editing the elevation. Try checking the browser console for more information. ${error.message}`
        )
      }
    }
  }
}
