import * as React from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { Label } from '@toasttab/buffet-pui-text-base'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SelectField } from '@toasttab/buffet-pui-forms'
import { ElevationsPercents, SpaVersion } from '@local/types'
import { EnvElevation } from '@local/types'
import { SelectOption } from '@toasttab/buffet-pui-select'
import { formatVersion } from '@local/utils/src/formatVersion'
import { mapBranchToLatestVersion } from '@local/utils/src/adhocVersions'
import { Modal } from './Modal'
import {
  ElevationFormValues,
  ELEVATIONS_FORM_SCHEMA,
  formToElevationValues
} from './shared'
import { ELEVATION_PERCENTS } from '../shared'
import { useEditElevation } from './useEditElevation'
import { useElevations } from '../Elevations'
import { useSpaControlSnackbar } from './useSpaControlSnackbar'

type Props = {
  spaName: string
  groupName: string
  isOpen: boolean
  close: () => void
  entry: EnvElevation
}

type FormValues = {
  gaVersion: string
  elevationVersion: string
  elevationPercent: string
}

const schema = Yup.object({
  ...ELEVATIONS_FORM_SCHEMA
})

export function EditElevationModal({
  spaName,
  groupName,
  isOpen,
  close,
  entry
}: Props) {
  const { spaVersions, adhocVersions } = useElevations()
  const { showSuccessSnackBar, showErrorSnackBar } = useSpaControlSnackbar()

  const [editElevation, { loading }] = useEditElevation({
    onSuccess: (spaElevation) => {
      close()
      showSuccessSnackBar(spaElevation, 'elevation')
    },
    onError: (err) => {
      showErrorSnackBar(err)
    }
  })
  function onSubmit(values: ElevationFormValues) {
    const variables = {
      spaId: spaName,
      elevation: {
        group: groupName,
        environment: entry.environment,
        elevations: formToElevationValues(values)
      }
    }
    editElevation({
      variables
    })
  }

  let versionOptions = spaVersions
  if (entry.environment === 'preprod') {
    versionOptions = versionOptions.concat([
      ...mapBranchToLatestVersion(adhocVersions).values()
    ])
  }

  type SelectVersionOption = SelectOption<Omit<SpaVersion, 'spaId'>>

  const selectVersionOptions: SelectVersionOption[] = versionOptions.map(
    (v) => ({
      label: v?.branch ? v.branch : formatVersion(v.version!),
      subLabel: v?.branch ? formatVersion(v.version!) : '',
      value: v
    })
  )

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header>Edit elevation</Modal.Header>
      <Formik
        validationSchema={schema}
        initialValues={{
          gaVersion: entry.elevation.gaVersion,
          elevationVersion: entry.elevation.elevationVersion || 'none',
          elevationPercent:
            (entry.elevation.elevationPercent as ElevationsPercents) || 'none'
        }}
        onSubmit={onSubmit}
      >
        <Form className='contents'>
          <Modal.Body>
            <div className='flex flex-col space-y-4 pb-1'>
              <ReadonlyText label='Spa' value={spaName} />
              <ReadonlyText label='Group' value={groupName} />
              <ReadonlyText label='Environment' value={entry.environment} />
              <SelectField<FormValues, string | undefined, SelectVersionOption>
                name='gaVersion'
                options={selectVersionOptions}
                itemToValue={(item) => item.value?.version}
                label='General availability'
              />
              <div>
                <Label>Elevation (optional)</Label>
                <div className='flex flex-row space-x-2'>
                  <SelectField<
                    FormValues,
                    string | undefined,
                    SelectVersionOption
                  >
                    name='elevationVersion'
                    options={[
                      {
                        label: 'none',
                        value: undefined
                      },
                      ...selectVersionOptions
                    ]}
                    itemToValue={(item) => item.value?.version}
                    containerClassName='flex-grow min-w-0'
                    label='Elevation version'
                    hideLabel
                  />
                  <SelectField
                    name='elevationPercent'
                    containerClassName='w-32'
                    options={['none', ...ELEVATION_PERCENTS]}
                    label='Elevation percent'
                    hideLabel
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='link'
              onClick={close}
              className='flex-grow sm:flex-none'
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              type='submit'
              className='flex-grow sm:flex-none'
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}
