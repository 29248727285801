export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type Elevations = {
  __typename?: 'Elevations';
  elevationPercent?: Maybe<Scalars['String']>;
  elevationVersion?: Maybe<Scalars['String']>;
  gaVersion: Scalars['String'];
};

export type ElevationsInput = {
  elevationPercent?: InputMaybe<Scalars['String']>;
  elevationVersion?: InputMaybe<Scalars['String']>;
  gaVersion: Scalars['String'];
};

export enum Environment {
  Prod = 'prod',
  Preprod = 'preprod',
  Sandbox = 'sandbox'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a SPA elevation on a group and environment */
  createSpaElevation: SpaElevation;
  /** Remove a SPA elevation from a group and environment */
  removeSpaElevation: RemovedSpaGroupElevation;
};


export type MutationCreateSpaElevationArgs = {
  spaId: Scalars['ID'];
  elevation: SpaElevationInput;
};


export type MutationRemoveSpaElevationArgs = {
  spaId: Scalars['ID'];
  group: Scalars['ID'];
  environment: Environment;
};

export type Query = {
  __typename?: 'Query';
  /** All of the SPA groups available */
  spaGroups: Array<Maybe<Scalars['ID']>>;
  /** All of the SPA elevations in all SPA groups */
  spaElevations: Array<Maybe<SpaElevation>>;
  spaEvents: Array<Maybe<SpaEvent>>;
  /** SPA release versions */
  spaVersions: Array<SpaVersion>;
  /** Adhoc feature branch versions */
  adhocVersions: Array<Maybe<SpaVersion>>;
};


export type QuerySpaElevationsArgs = {
  spaId: Scalars['ID'];
};


export type QuerySpaEventsArgs = {
  spaId: Scalars['ID'];
  maxAge?: InputMaybe<Scalars['String']>;
  maxCount?: InputMaybe<Scalars['Int']>;
};


export type QuerySpaVersionsArgs = {
  spaId: Scalars['ID'];
  environment?: InputMaybe<Environment>;
};


export type QueryAdhocVersionsArgs = {
  spaId: Scalars['ID'];
};

export type RemovedSpaGroupElevation = {
  __typename?: 'RemovedSpaGroupElevation';
  spaId: Scalars['ID'];
  group: Scalars['ID'];
  environment: Environment;
};

/** A SPA elevation for a particular SPA group and environment */
export type SpaElevation = {
  __typename?: 'SpaElevation';
  spaId: Scalars['ID'];
  group: Scalars['ID'];
  environment: Environment;
  elevations: Elevations;
};

export type SpaElevationInput = {
  environment: Environment;
  group: Scalars['String'];
  elevations: ElevationsInput;
};

export type SpaEvent = {
  __typename?: 'SpaEvent';
  spaId: Scalars['ID'];
  date: Scalars['String'];
  type: SpaEventType;
  user: Scalars['String'];
  description: Scalars['String'];
  environment: Environment;
};

export enum SpaEventType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Modify = 'MODIFY'
}

export type SpaVersion = {
  __typename?: 'SpaVersion';
  spaId: Scalars['String'];
  version: Scalars['String'];
  assets: Scalars['JSONObject'];
  commitMsg?: Maybe<Scalars['String']>;
  commitAuthor?: Maybe<Scalars['String']>;
  commitDate?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
};

export type AdhocVersionsQueryVariables = Exact<{
  spaId: Scalars['ID'];
}>;


export type AdhocVersionsQuery = { __typename?: 'Query', adhocVersions: Array<{ __typename?: 'SpaVersion', version: string, assets: any, commitAuthor?: string | null, commitDate?: string | null, commitMsg?: string | null, branch?: string | null } | null> };

export type SpaVersionsQueryVariables = Exact<{
  spaId: Scalars['ID'];
  environment: Environment;
}>;


export type SpaVersionsQuery = { __typename?: 'Query', spaVersions: Array<{ __typename?: 'SpaVersion', version: string, assets: any, commitAuthor?: string | null, commitDate?: string | null, commitMsg?: string | null, branch?: string | null }> };

export type SpaBuildVersionsQueryVariables = Exact<{
  spaId: Scalars['ID'];
}>;


export type SpaBuildVersionsQuery = { __typename?: 'Query', spaVersions: Array<{ __typename?: 'SpaVersion', version: string, assets: any }> };

export type SpaGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type SpaGroupsQuery = { __typename?: 'Query', spaGroups: Array<string | null> };

export type RemoveSpaGroupElevationMutationVariables = Exact<{
  spaId: Scalars['ID'];
  group: Scalars['ID'];
  environment: Environment;
}>;


export type RemoveSpaGroupElevationMutation = { __typename?: 'Mutation', removeSpaElevation: { __typename?: 'RemovedSpaGroupElevation', spaId: string, group: string, environment: Environment } };

export type CreateSpaGroupElevationMutationVariables = Exact<{
  spaId: Scalars['ID'];
  elevation: SpaElevationInput;
}>;


export type CreateSpaGroupElevationMutation = { __typename?: 'Mutation', createSpaElevation: { __typename?: 'SpaElevation', spaId: string, group: string, environment: Environment, elevations: { __typename?: 'Elevations', gaVersion: string, elevationVersion?: string | null, elevationPercent?: string | null } } };

export type SpaElevationsQueryVariables = Exact<{
  spaId: Scalars['ID'];
}>;


export type SpaElevationsQuery = { __typename?: 'Query', spaElevations: Array<{ __typename?: 'SpaElevation', spaId: string, group: string, environment: Environment, elevations: { __typename?: 'Elevations', gaVersion: string, elevationVersion?: string | null, elevationPercent?: string | null } } | null> };

export type SpaEventsQueryVariables = Exact<{
  spaId: Scalars['ID'];
  maxCount?: InputMaybe<Scalars['Int']>;
  maxAge?: InputMaybe<Scalars['String']>;
}>;


export type SpaEventsQuery = { __typename?: 'Query', spaEvents: Array<{ __typename?: 'SpaEvent', spaId: string, date: string, user: string, type: SpaEventType, description: string, environment: Environment } | null> };
