import { ApolloError, useMutation } from '@apollo/client'
import {
  MutationCreateSpaElevationArgs,
  SpaElevation,
  CreateSpaGroupElevationMutation
} from '@local/types'
import { CREATE_SPA_ELEVATION } from './shared'

type EditElevationProps = {
  onSuccess: (data: SpaElevation) => void
  onError: (err: ApolloError) => void
}

export function useEditElevation({ onSuccess, onError }: EditElevationProps) {
  const result = useMutation<
    CreateSpaGroupElevationMutation,
    MutationCreateSpaElevationArgs
  >(CREATE_SPA_ELEVATION, {
    onCompleted: ({ createSpaElevation: data }) => {
      onSuccess(data)
    },
    onError,
    refetchQueries: ['SpaEvents']
  })
  return result
}
