import * as React from 'react'
import {
  Table,
  Head,
  Body,
  Row,
  HeadingCell,
  Cell
} from '@toasttab/buffet-pui-table'
import { gql, useQuery } from '@apollo/client'
import {
  Environment,
  QuerySpaVersionsArgs,
  SpaVersionsQuery
} from '@local/types'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import {
  LookupChecksIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useShowMore } from '@toasttab/use-show-more'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { CardContainer, CardContainerProps } from '@toasttab/buffet-pui-card'
import { VersionsLoading } from './VersionsLoading'
import { GitLogDetails } from '../GitLogDetails'
import { AssetItem } from '../AssetItem'

export const GET_SPA_VERSIONS = gql`
  query SpaVersions($spaId: ID!, $environment: Environment!) {
    spaVersions(spaId: $spaId, environment: $environment) {
      version
      assets
      commitAuthor
      commitDate
      commitMsg
      branch
    }
  }
`

export function ReleaseVersions({ spaId }: { spaId: string }) {
  const { data, loading, error } = useQuery<
    SpaVersionsQuery,
    QuerySpaVersionsArgs
  >(GET_SPA_VERSIONS, {
    variables: { spaId, environment: Environment.Preprod }
  })

  const { displayedData, hasMore, showMore, nextPageSize } = useShowMore({
    initialShown: 2,
    data: data?.spaVersions ?? []
  })
  const [checked, setChecked] = React.useState<Array<string>>([])
  React.useEffect(() => {
    // Clear out the checked versions when switching spas
    setChecked([])
  }, [spaId])
  const { showErrorSnackBar } = useSnackBar()

  if (loading) {
    return (
      <Layout noPadding>
        <VersionsLoading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout>
        <div className='w-full m-auto mb-6 lg:w-1/3'>
          <EmptyState
            icon={<WarningOutlineIcon accessibility='decorative' />}
            title='Uh oh, something went wrong'
          >
            There was an issue displaying the SPA versions.
          </EmptyState>
        </div>
      </Layout>
    )
  }

  if (!data!.spaVersions.length) {
    return (
      <Layout>
        <div className='w-full m-auto mb-6 lg:w-1/3'>
          <EmptyState
            icon={<LookupChecksIcon accessibility='decorative' />}
            title='No results found'
          >
            New versions for {spaId} will appear here once published by the
            build process.
          </EmptyState>
        </div>
      </Layout>
    )
  }

  return (
    <Layout noPadding>
      <Table variant='plain' aria-label='Release versions table'>
        <Head>
          <Row className='relative'>
            <HeadingCell className='w-0'></HeadingCell>
            <HeadingCell className='w-0'>Version</HeadingCell>
            <HeadingCell>Details</HeadingCell>
            {checked.length > 0 && (
              <div className='absolute top-0 left-0 w-full h-full px-px pt-px'>
                <div className='flex items-center h-full px-1 bg-white rounded-t'>
                  <Button
                    variant='link'
                    as='a'
                    href={`https://github.toasttab.com/toasttab/${spaId}/compare/${checked[0]}...${checked[1]}`}
                    target='_blank'
                    onClick={(e: any) => {
                      if (checked.length !== 2) {
                        showErrorSnackBar(
                          'Please select exactly two versions to compare',
                          {
                            onDismiss: () => {}
                          }
                        )
                        e.preventDefault()
                      }
                    }}
                  >
                    Compare on github
                  </Button>
                  {checked.length !== 2 && (
                    <span className='type-subhead text-secondary'>
                      (Select two versions)
                    </span>
                  )}
                </div>
              </div>
            )}
          </Row>
        </Head>
        <Body>
          {displayedData.map(
            ({ version, assets, commitMsg, commitAuthor, commitDate }) => {
              const assetRecords = assets as Record<string, string>
              const hasCommitDetails = Boolean(
                commitMsg && commitAuthor && commitDate
              )
              return (
                <Row key={`${spaId}-${version}`}>
                  <Cell className='pt-0.5 pb-0'>
                    <Checkbox
                      label={`${spaId} version ${version}`}
                      hideLabel
                      checked={checked.includes(version)}
                      value={version}
                      onChange={() => {
                        if (checked.includes(version)) {
                          setChecked(arrayRemove(checked, version))
                        } else {
                          setChecked(
                            arrayAdd(checked, version).sort((a, b) => {
                              const aNumber = Number(a)
                              const bNumber = Number(b)
                              if (aNumber && bNumber) {
                                return aNumber - bNumber
                              }
                              return a.localeCompare(b)
                            })
                          )
                        }
                      }}
                    />
                  </Cell>
                  <Cell>
                    <a
                      href={`https://github.toasttab.com/toasttab/${spaId}/releases/tag/${version}`}
                    >
                      {version}
                    </a>
                  </Cell>
                  <Cell>
                    <section>
                      <h5 className='font-semibold type-default'>
                        Import map entries
                      </h5>
                      {Object.entries(assetRecords).map(([name, asset]) => (
                        <AssetItem key={name} name={name} value={asset} />
                      ))}
                    </section>
                    {hasCommitDetails && (
                      <>
                        <hr className='h-px p-0 my-3 border-0 bg-darken-12' />
                        <GitLogDetails
                          spaId={spaId}
                          commitAuthor={commitAuthor}
                          commitDate={commitDate}
                          commitMsg={commitMsg}
                        />
                      </>
                    )}
                  </Cell>
                </Row>
              )
            }
          )}
        </Body>
      </Table>
      {hasMore && (
        <div className='flex flex-row justify-center py-4'>
          <Button variant='link' onClick={showMore}>
            Show more ({nextPageSize})
          </Button>
        </div>
      )}
    </Layout>
  )
}

function Layout({ children, ...props }: CardContainerProps) {
  return (
    <div>
      <h2 id='versions-title' className='px-4 mb-2 type-headline-4 md:px-0'>
        Release versions
      </h2>
      <CardContainer aria-labelledby='versions-title' {...props}>
        <div className='overflow-x-auto'>{children}</div>
      </CardContainer>
    </div>
  )
}

function arrayRemove<T>(arr: Array<T>, value: T) {
  return arr.filter((ele) => ele !== value)
}

function arrayAdd<T>(arr: Array<T>, value: T) {
  return arr.concat([value])
}
