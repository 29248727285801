import { EnvElevation, SpaGroupElevations } from '@local/types'
import { SpaElevation } from '@local/types/src/schemaTypes'

export function groupBySpaGroups(
  spaElevations: SpaElevation[]
): SpaGroupElevations[] {
  return Object.values(
    spaElevations.reduce(
      (acc: Record<string, SpaGroupElevations>, curr: SpaElevation) => {
        const { group, elevations, environment } = curr
        if (acc[group]) {
          acc[group].entries.push({
            environment,
            elevation: elevations
          })
        } else {
          acc[group] = {
            group: group,
            entries: [
              {
                environment,
                elevation: elevations
              }
            ]
          }
        }
        return acc
      },
      {}
    )
  )
}

const SORT_WEIGHT = {
  preprod: 0,
  sandbox: 1,
  prod: 2
}

export function sortByEnv(envA: EnvElevation, envB: EnvElevation) {
  return SORT_WEIGHT[envA.environment] - SORT_WEIGHT[envB.environment]
}
