import * as React from 'react'

export function useModal() {
  const [isOpen, setIsOpen] = React.useState(false)
  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false)
  }
}
