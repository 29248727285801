import * as React from 'react'
import { AddIcon, MoreHorizIcon } from '@toasttab/buffet-pui-icons'
import { useParams } from 'react-router-dom'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { PathParams, SpaGroupElevations } from '@local/types'
import { AddElevationModal } from '../Modals/AddElevationModal'
import { ElevationsGroup } from './ElevationsGroup'
import { useModal } from '../Modals'

type Props = {
  groups: SpaGroupElevations[]
}

export function ElevationsDetails({ groups }: Props) {
  const { repo: spaId = '' } = useParams<PathParams>()

  const { isOpen: isAddOpen, close: closeAdd, open: openAdd } = useModal()

  return (
    <div>
      <div className='flex flex-row items-start w-full'>
        <div className='flex-grow px-4 pr-2 type-headline-4 md:px-0'>
          SPA Groups
        </div>
        <MenuDropdown
          renderToggle={(props) => (
            <IconButton
              aria-haspopup='listbox'
              icon={<MoreHorizIcon aria-label='Groups menu' />}
              className='-my-2'
              iconColor='secondary'
              {...props}
            />
          )}
        >
          <ListItem
            icon={<AddIcon accessibility='decorative' />}
            label='Add to group'
            onClick={openAdd}
          />
        </MenuDropdown>
      </div>
      {groups.length > 0 ? (
        <div className='space-y-4'>
          {groups.map((group) => (
            <ElevationsGroup
              key={group.group}
              spaName={spaId}
              groupName={group.group}
              entries={group.entries}
            />
          ))}
        </div>
      ) : (
        <div className='w-full px-1 mt-1 mb-10 text-secondary'>
          Click the ... icon above to add this spa to an import map group
        </div>
      )}
      <AddElevationModal isOpen={isAddOpen} close={closeAdd} />
    </div>
  )
}
