import * as React from 'react'
import {
  Table,
  Head,
  Body,
  Row,
  HeadingCell,
  Cell
} from '@toasttab/buffet-pui-table'
import { gql, useQuery } from '@apollo/client'
import { QuerySpaVersionsArgs, SpaVersion } from '@local/types'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useShowMore } from '@toasttab/use-show-more'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { formatVersion } from '@local/utils/src/formatVersion'
import { GitLogDetails } from '../GitLogDetails'
import { AssetItem } from '../AssetItem'

export const GET_ADHOC_VERSIONS = gql`
  query AdhocVersions($spaId: ID!) {
    adhocVersions(spaId: $spaId) {
      version
      assets
      commitAuthor
      commitDate
      commitMsg
      branch
    }
  }
`

export type VersionsResponse = {
  adhocVersions: SpaVersion[]
}

export function AdhocVersions({ spaId }: { spaId: string }) {
  const { data, loading } = useQuery<VersionsResponse, QuerySpaVersionsArgs>(
    GET_ADHOC_VERSIONS,
    {
      variables: { spaId }
    }
  )

  const { displayedData, hasMore, showMore, nextPageSize } = useShowMore({
    initialShown: 2,
    data: data?.adhocVersions ?? []
  })

  if (loading || !data?.adhocVersions.length) {
    // purposefully not show anything as this is opt-in
    return null
  }

  return (
    <div>
      <h2
        id='adhoc-versions-title'
        className='px-4 mb-2 type-headline-4 md:px-0'
      >
        Adhoc versions
      </h2>
      <CardContainer aria-labelledby='adhoc-versions-title' noPadding>
        <div className='overflow-x-auto'>
          <Table aria-label='Adhoc versions table' variant='plain'>
            <Head>
              <Row className='relative'>
                <HeadingCell>Branch</HeadingCell>
                <HeadingCell>Version</HeadingCell>
                <HeadingCell>Latest build</HeadingCell>
              </Row>
            </Head>
            <Body>
              {displayedData.map(
                ({
                  branch,
                  assets,
                  version,
                  commitMsg,
                  commitAuthor,
                  commitDate
                }) => {
                  const assetRecords = assets as Record<string, string>
                  const hasCommitDetails = Boolean(
                    commitMsg && commitAuthor && commitDate
                  )
                  return (
                    <Row key={`${spaId}-${branch}-${version}`}>
                      <Cell>{branch}</Cell>
                      <Cell>{formatVersion(version)}</Cell>
                      <Cell>
                        <section>
                          <h5 className='font-semibold type-default'>
                            Import map entries
                          </h5>
                          {Object.entries(assetRecords).map(([name, asset]) => (
                            <AssetItem key={name} name={name} value={asset} />
                          ))}
                        </section>
                        {hasCommitDetails && (
                          <>
                            <hr className='h-px p-0 my-3 border-0 bg-darken-12' />
                            <GitLogDetails
                              spaId={spaId}
                              commitAuthor={commitAuthor}
                              commitDate={commitDate}
                              commitMsg={commitMsg}
                            />
                          </>
                        )}
                      </Cell>
                    </Row>
                  )
                }
              )}
            </Body>
          </Table>
        </div>
        {hasMore && (
          <div className='flex flex-row justify-center py-4'>
            <Button variant='link' onClick={showMore}>
              Show more ({nextPageSize})
            </Button>
          </div>
        )}
      </CardContainer>
    </div>
  )
}
