import { VersionsResponse } from '@local/basic-information/src/AdhocVersions'
import { SpaVersion } from '@local/types'

// Should be used in spa-dev-tools and other places that selects
export function mapBranchToLatestVersion(
  adhocVersions: VersionsResponse['adhocVersions']
): Map<string, SpaVersion> {
  const map = new Map()
  const commits = adhocVersions.slice()
  commits.sort((first, second) => {
    if (!first.commitDate || !second.commitDate) return 0
    return Date.parse(second.commitDate) - Date.parse(first.commitDate)
  })
  commits.forEach((commit) => {
    if (!map.has(commit.branch)) {
      map.set(commit.branch, commit)
    }
  })
  return map
}
