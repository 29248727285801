import * as React from 'react'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { TadpoleApolloProvider } from '@local/tadpole-node-client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Elevations } from '@local/elevations'
import { BasicInformation } from '@local/basic-information'
import { EventHistory } from '@local/event-history'

export function App() {
  const basename = window.location.pathname.startsWith('/node')
    ? '/node'
    : undefined

  return (
    <BrowserRouter basename={basename}>
      <TadpoleApolloProvider>
        <SnackBarProvider>
          <Routes>
            <Route path='/:repo/*' element={<Main />} />
          </Routes>
        </SnackBarProvider>
      </TadpoleApolloProvider>
    </BrowserRouter>
  )
}

export function Main() {
  return (
    <div className='min-h-screen pt-2 space-y-8 type-default'>
      <Elevations />
      <BasicInformation />
      <EventHistory />
    </div>
  )
}
