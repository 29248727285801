import { gql } from '@apollo/client'
import * as Yup from 'yup'
import { ElevationsPercents } from '@local/types'
import { ELEVATION_PERCENTS } from '../shared'

export const CREATE_SPA_ELEVATION = gql`
  mutation CreateSpaGroupElevation(
    $spaId: ID!
    $elevation: SpaElevationInput!
  ) {
    createSpaElevation(spaId: $spaId, elevation: $elevation) {
      spaId
      group
      environment
      elevations {
        gaVersion
        elevationVersion
        elevationPercent
      }
    }
  }
`

export const ELEVATIONS_FORM_SCHEMA = {
  gaVersion: Yup.string().required('GA elevation is required'),
  elevationVersion: Yup.string(),
  elevationPercent: Yup.mixed()
    .oneOf(['none'])
    .when('elevationVersion', {
      is: (elevationVersionVal: string) =>
        Boolean(elevationVersionVal) && elevationVersionVal !== 'none',
      then: () => Yup.mixed().oneOf(ELEVATION_PERCENTS).required(),
      otherwise: () => Yup.mixed().oneOf(['none']).required()
    })
}

export type ElevationFormValues = {
  gaVersion: string
  elevationVersion: string
  elevationPercent?: ElevationsPercents | 'none'
}

export function formToElevationValues(values: ElevationFormValues) {
  const hasElevationValueAndPercent =
    values.elevationVersion !== 'none' && values.elevationPercent !== 'none'
  return {
    gaVersion: values.gaVersion,
    elevationVersion: hasElevationValueAndPercent
      ? values.elevationVersion
      : undefined,
    elevationPercent: hasElevationValueAndPercent
      ? values.elevationPercent
      : undefined
  }
}
