import * as React from 'react'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export function VersionsLoading() {
  return (
    <>
      <Table>
        <Head>
          <Row>
            <HeadingCell className='w-1/4'>
              <Skeleton className='w-1/2 h-4 my-1' />
            </HeadingCell>
            <HeadingCell className='w-3/4'>
              <Skeleton className='w-1/2 h-4 my-1' />
            </HeadingCell>
          </Row>
        </Head>
        <Body>
          <TableRow />
          <TableRow />
          <TableRow />
          <TableRow />
          <TableRow />
        </Body>
      </Table>
      <div className='flex justify-center py-4'>
        <Skeleton className='w-32 h-10' />
      </div>
    </>
  )
}

function TableRow() {
  return (
    <Row>
      <TableCell />
      <TableCell />
    </Row>
  )
}

function TableCell() {
  return (
    <Cell>
      <Skeleton className='w-1/2 h-4 my-1' />
    </Cell>
  )
}
