import * as React from 'react'
import { useParams } from 'react-router-dom'
import { AdhocVersions } from '../AdhocVersions'
import { ReleaseVersions } from '../ReleaseVersions'

export function BasicInformation() {
  const { repo: spaId = '' } = useParams<{ repo: string }>()

  return (
    <div className='space-y-8'>
      <ReleaseVersions spaId={spaId} />
      <AdhocVersions spaId={spaId} />
    </div>
  )
}
