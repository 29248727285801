import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import * as React from 'react'
import { useClipboard } from 'use-clipboard-copy'

export function AssetItem({ name, value }: { name: string; value: string }) {
  const { copied, copy } = useClipboard({
    copiedTimeout: 1000
  })
  return (
    <div className='type-default'>
      <span>
        {name}
        {': '}
      </span>
      <Tooltip
        content={() => (copied ? 'Copied' : 'Copy')}
        variant='dark'
        className='inline-block'
        placement='top'
      >
        <span className='block cursor-pointer' onClick={() => copy(value)}>
          {value.startsWith('//cdn.toasttab.com/spa')
            ? value.split('/').slice(-2).join('/')
            : value}
        </span>
      </Tooltip>
    </div>
  )
}
