import * as React from 'react'
import * as Yup from 'yup'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { Label } from '@toasttab/buffet-pui-text-base'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Formik, Form, useFormikContext } from 'formik'
import { SelectField, TextInputField } from '@toasttab/buffet-pui-forms'
import { gql, useMutation, useQuery } from '@apollo/client'
import {
  CreateSpaGroupElevationMutation,
  MutationCreateSpaElevationArgs,
  PathParams,
  SpaElevation
} from '@local/types'
import { Environment } from '@local/types'
import { useParams } from 'react-router-dom'
import { Modal } from './Modal'
import {
  CREATE_SPA_ELEVATION,
  ElevationFormValues,
  ELEVATIONS_FORM_SCHEMA,
  formToElevationValues
} from './shared'
import {
  ELEVATION_PERCENTS,
  GET_SPA_ELEVATIONS,
  SpaElevationsResponse
} from '../shared'
import { useElevations } from '../Elevations'
import { useSpaControlSnackbar } from './useSpaControlSnackbar'

const schema = Yup.object({
  group: Yup.string().required('Group is required'),
  environment: Yup.mixed()
    .oneOf(Object.values(Environment))
    .required('Environment is required'),
  ...ELEVATIONS_FORM_SCHEMA
})

type Props = {
  isOpen: boolean
  close: () => void
}

type FormValues = {
  group: string
  environment: Environment | ''
} & ElevationFormValues

const initialValues: FormValues = {
  group: '',
  environment: '',
  gaVersion: '',
  elevationVersion: 'none',
  elevationPercent: 'none'
}

type AllGroupsResponse = {
  spaGroups: string[]
}

const GET_SPA_GROUPS = gql`
  query SpaGroups {
    spaGroups
  }
`

export function AddElevationModal({ isOpen, close }: Props) {
  const { spaVersions } = useElevations()
  const { repo: spaId = '' } = useParams<PathParams>()

  const {
    data,
    loading: allGroupsLoading,
    error: allGroupsError
  } = useQuery<AllGroupsResponse>(GET_SPA_GROUPS)
  const [addElevation, { loading: addLoading }] = useAddElevation({
    spaId,
    onSuccess: close
  })

  function onSubmit(values: FormValues) {
    const variables = {
      spaId,
      elevation: {
        group: values.group,
        environment: values.environment as Environment,
        elevations: formToElevationValues(values)
      }
    }
    addElevation({
      variables
    })
  }

  if (allGroupsLoading) {
    return null
  }

  if (allGroupsError) {
    return null
  }

  const spaVersionStrings = spaVersions.map(({ version }) => version)

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header>Add to group</Modal.Header>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <Form className='contents'>
          <Modal.Body>
            <div className='flex flex-col space-y-4 pb-1'>
              <ReadonlyText label='Spa' value={spaId} />
              <SpaGroupField spaGroups={data?.spaGroups || []} />
              <SelectField
                label='Environment'
                name='environment'
                containerClassName='w-full'
                options={[...Object.values(Environment)] as string[]}
              />
              <SelectField
                label='General availability'
                name='gaVersion'
                options={spaVersionStrings}
              />
              <div>
                <Label>Elevation (optional)</Label>
                <div className='flex flex-row space-x-2'>
                  <SelectField
                    label='Elevation version'
                    hideLabel
                    name='elevationVersion'
                    options={['none', ...spaVersionStrings]}
                    containerClassName='flex-grow min-w-0'
                  />
                  <SelectField
                    label='Elevation percent'
                    hideLabel
                    name='elevationPercent'
                    containerClassName='w-32'
                    options={['none', ...ELEVATION_PERCENTS]}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='link'
              onClick={close}
              className='flex-grow sm:flex-none'
            >
              Cancel
            </Button>
            <Button
              disabled={addLoading}
              type='submit'
              className='flex-grow sm:flex-none'
            >
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Formik>
    </Modal>
  )
}

function SpaGroupField({ spaGroups }: { spaGroups: string[] }) {
  const [createSpaGroup, toggleCreateSpaGroup] = React.useState<boolean>(false)
  const { setFieldValue } = useFormikContext()

  function handleSpaGroupToggle() {
    setFieldValue('group', '', false)
    toggleCreateSpaGroup(!createSpaGroup)
  }

  return (
    <div>
      {createSpaGroup ? (
        <TextInputField
          name='group'
          label='New SPA group name'
          helperText='This will create a new SPA group. You should make sure you have a view that renders this group before proceeding.'
        />
      ) : (
        <SelectField options={spaGroups} name='group' label='SPA group name' />
      )}
      <Button
        variant='text-link'
        onClick={handleSpaGroupToggle}
        className='mt-1'
      >
        {createSpaGroup
          ? 'Use an existing SPA group'
          : 'Create a new SPA group'}
      </Button>
    </div>
  )
}

type AddElevationProps = {
  spaId: string
  onSuccess: () => void
  onError?: () => void
}

function useAddElevation({ spaId, onSuccess, onError }: AddElevationProps) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSpaControlSnackbar()

  const result = useMutation<
    CreateSpaGroupElevationMutation,
    MutationCreateSpaElevationArgs
  >(CREATE_SPA_ELEVATION, {
    onCompleted: ({ createSpaElevation }) => {
      onSuccess()
      showSuccessSnackBar(createSpaElevation, 'elevation')
    },
    onError: (err) => {
      onError && onError()
      showErrorSnackBar(err)
    },
    refetchQueries: ['SpaEvents'],
    update: (cache, { data }) => {
      const queryOptions = {
        query: GET_SPA_ELEVATIONS,
        variables: {
          spaId
        }
      }
      const existingGroupsData =
        cache.readQuery<SpaElevationsResponse>(queryOptions)
      if (data && existingGroupsData) {
        cache.writeQuery({
          ...queryOptions,
          data: {
            spaElevations: getUpdatedSpaGroupElevations(
              data.createSpaElevation,
              existingGroupsData.spaElevations
            )
          }
        })
      }
    }
  })
  return result
}

function getUpdatedSpaGroupElevations(
  newSpaElevation: SpaElevation,
  existingSpaElevations: SpaElevation[]
) {
  // Edge case - cache update when new SPA elevation is "created" on the UI
  // when it already exist
  const elevationInExistingIndex = existingSpaElevations.findIndex(
    (spaElevation) =>
      spaElevation.group === newSpaElevation.group &&
      spaElevation.environment === newSpaElevation.environment
  )
  return elevationInExistingIndex === -1
    ? [...existingSpaElevations, newSpaElevation]
    : existingSpaElevations.map((spaElevation, index) =>
        index === elevationInExistingIndex ? newSpaElevation : spaElevation
      )
}
