import * as React from 'react'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import {
  DeleteIcon,
  LockLockedIcon,
  LockUnlockedIcon,
  MoreHorizIcon
} from '@toasttab/buffet-pui-icons'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { EnvElevation } from '@local/types'
import { formatVersion } from '@local/utils/src/formatVersion'

type ElevationCardProps = {
  entry: EnvElevation
  onEdit: (entry: EnvElevation) => void
  onRemove: (entry: EnvElevation) => void
  onRemoveElevation: (entry: EnvElevation) => void
}

export function ElevationCard({
  entry,
  onEdit,
  onRemove,
  onRemoveElevation
}: ElevationCardProps) {
  const {
    environment,
    elevation: { elevationVersion, elevationPercent, gaVersion }
  } = entry
  const [locked, setLocked] = React.useState<boolean>(environment === 'prod')
  return (
    <CardContainer noPadding className='py-4'>
      <div className='px-4'>
        <div className='flex flex-row items-start mb-4 md:mb-6'>
          <h2 className='flex-grow pr-2 font-bold type-headline-5'>
            {environment}
          </h2>
          <MenuDropdown
            renderToggle={(props) => (
              <IconButton
                aria-haspopup='listbox'
                iconColor='secondary'
                icon={
                  <MoreHorizIcon
                    aria-label={`${environment} environment menu`}
                  />
                }
                className='-m-2'
                {...props}
              />
            )}
          >
            <ListItem
              icon={<DeleteIcon accessibility='decorative' />}
              label='Remove from group'
              onClick={() => onRemove(entry)}
            />
          </MenuDropdown>
        </div>
        <div className='space-y-4'>
          <ReadonlyText
            label='General availability'
            // @ts-ignore (temp until we update type in buffet for readonly component)
            value={
              <div className='type-headline-5'>{formatVersion(gaVersion)}</div>
            }
            testId={`${environment}-ga-version`}
          />
          <ReadonlyText
            label='Elevation'
            // @ts-ignore (temp until we update type in buffet for readonly component)
            value={
              elevationVersion ? (
                <span className='flex items-center'>
                  <span
                    className='mr-1 truncate type-large'
                    title={elevationVersion}
                  >
                    {formatVersion(elevationVersion)}
                  </span>{' '}
                  ({elevationPercent})
                  <span className='pl-1'>
                    <IconButton
                      textClassName='text-warning'
                      cropToIcon
                      icon={
                        <DeleteIcon
                          aria-label={`Remove ${environment} environment elevation`}
                        />
                      }
                      onClick={() => onRemoveElevation(entry)}
                    />
                  </span>
                </span>
              ) : (
                'none'
              )
            }
            testId={`${environment}-elevation-version-percent`}
          />
        </div>
        <div className='mt-6 flex flex-row gap-4 md:gap-2 items-center flex-wrap'>
          {environment === 'prod' && (
            <IconButton
              iconColor='secondary'
              icon={
                locked ? (
                  <LockLockedIcon aria-label='Unlock elevation' />
                ) : (
                  <LockUnlockedIcon aria-label='Lock elevation' />
                )
              }
              onClick={() => setLocked(!locked)}
            />
          )}
          <Button
            aria-label={`${environment} edit elevation`}
            variant='secondary'
            disabled={locked}
            onClick={() => onEdit(entry)}
          >
            Edit elevation
          </Button>
        </div>
      </div>
    </CardContainer>
  )
}
