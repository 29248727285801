import * as React from 'react'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { Button } from '@toasttab/buffet-pui-buttons'
import { MutationCreateSpaElevationArgs } from '@local/types'
import { EnvElevation } from '@local/types'
import { Modal } from './Modal'
import { useEditElevation } from './useEditElevation'
import { useSpaControlSnackbar } from './useSpaControlSnackbar'

type Props = {
  spaName: string
  groupName: string
  isOpen: boolean
  close: () => void
  entry: EnvElevation
}

export function RemoveElevationModal({
  spaName,
  groupName,
  isOpen,
  close,
  entry
}: Props) {
  const { showSuccessSnackBar, showErrorSnackBar } = useSpaControlSnackbar()
  const [editElevation, { loading }] = useEditElevation({
    onSuccess: (spaElevation) => {
      close()
      showSuccessSnackBar(spaElevation, 'removal')
    },
    onError: (err) => {
      showErrorSnackBar(err)
    }
  })

  function onRemove() {
    const variables: MutationCreateSpaElevationArgs = {
      spaId: spaName,
      elevation: {
        group: groupName,
        environment: entry.environment,
        elevations: {
          elevationPercent: null,
          elevationVersion: null,
          gaVersion: entry.elevation.gaVersion
        }
      }
    }
    editElevation({
      variables
    })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <Modal.Header>Remove SPA elevation</Modal.Header>
      <Modal.Body className='space-y-4'>
        <ReadonlyText label='Spa' value={spaName} />
        <ReadonlyText label='Group' value={groupName} />
        <ReadonlyText label='Environment' value={entry.environment} />
        <p className='type-default text-warning'>
          This will remove elevation from this environment and SPA group and
          maintain the GA version
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='link' onClick={close}>
          Cancel
        </Button>
        <Button variant='destructive' onClick={onRemove} disabled={loading}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
