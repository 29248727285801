import { gql } from '@apollo/client'
import { SpaElevation } from '@local/types'

export const GET_SPA_ELEVATIONS = gql`
  query SpaElevations($spaId: ID!) {
    spaElevations(spaId: $spaId) {
      spaId
      group
      environment
      elevations {
        gaVersion
        elevationVersion
        elevationPercent
      }
    }
  }
`

export const ELEVATION_PERCENTS = ['1%', '10%', '50%', 'canary', 'rc']

export type SpaElevationsResponse = {
  spaElevations: SpaElevation[]
}
