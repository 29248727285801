import * as React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { cacheOptions } from '../shared'

const httpLink = new HttpLink({
  uri: process.env.SPA_CONTROL_BASE_URL || '/v1/graphql'
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('tadpoleJWT') ?? ''
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(cacheOptions)
})

type Props = {
  children: React.ReactNode
}

export const TadpoleApolloProvider = ({ children }: Props) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
