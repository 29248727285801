import * as React from 'react'
import { ApolloError, gql, useQuery } from '@apollo/client'
import { SpaElevation, QuerySpaVersionsArgs } from '@local/types'
import { useParams } from 'react-router-dom'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineDisplayIcon } from '@toasttab/buffet-pui-icons'
import { PathParams, SpaVersionsQuery } from '@local/types'
import {
  GET_ADHOC_VERSIONS,
  VersionsResponse
} from '@local/basic-information/src/AdhocVersions'
import { ElevationsDetails } from './ElevationsDetails'
import { GET_SPA_ELEVATIONS } from '../shared'
import { groupBySpaGroups } from './helpers'
import { ElevationsLoading } from './ElevationsLoading'

export const SPA_VERSIONS = gql`
  query SpaBuildVersions($spaId: ID!) {
    spaVersions(spaId: $spaId) {
      version
      assets
    }
  }
`

type SpaGroupElevationResponse = {
  spaElevations: SpaElevation[]
}

interface ElevationsInitialData {
  spaVersions: SpaVersionsQuery['spaVersions']
  adhocVersions: VersionsResponse['adhocVersions']
}

export const ElevationsContext = React.createContext<ElevationsInitialData>({
  spaVersions: [],
  adhocVersions: []
})

export const ElevationsProvider = ({
  value,
  children
}: {
  value: ElevationsInitialData
  children: React.ReactNode
}) => {
  return (
    <ElevationsContext.Provider value={value}>
      {children}
    </ElevationsContext.Provider>
  )
}

export const useElevations = () => {
  return React.useContext(ElevationsContext)
}

export function Elevations() {
  const { repo: spaId = '' } = useParams<PathParams>()

  const {
    spaVersions = [],
    adhocVersions = [],
    spaElevations = [],
    loading,
    errors
  } = useElevationsInitialData(spaId)

  if (loading) {
    return <ElevationsLoading />
  }

  if (errors?.length) {
    return (
      <div className='w-full px-8 py-8 m-auto mb-6 lg:w-1/3'>
        <EmptyState
          icon={<WarningOutlineDisplayIcon accessibility='decorative' />}
          title='Uh oh, something went wrong'
        >
          There was an issue displaying the SPA elevations.
        </EmptyState>
      </div>
    )
  }

  return (
    <ElevationsProvider
      value={{
        spaVersions,
        adhocVersions
      }}
    >
      <ElevationsDetails groups={spaElevations} />
    </ElevationsProvider>
  )
}

function useElevationsInitialData(spaId: string) {
  const {
    loading: spaElevationsLoading,
    data: spaElevationsData,
    error: spaElevationsError
  } = useQuery<SpaGroupElevationResponse, { spaId: string }>(
    GET_SPA_ELEVATIONS,
    {
      variables: { spaId }
    }
  )

  const {
    loading: versionsLoading,
    data: versionsData,
    error: versionsError
  } = useQuery<SpaVersionsQuery, QuerySpaVersionsArgs>(SPA_VERSIONS, {
    variables: { spaId }
  })

  const {
    data: adhocData,
    loading: adhocLoading,
    error: adhocError
  } = useQuery<VersionsResponse, QuerySpaVersionsArgs>(GET_ADHOC_VERSIONS, {
    variables: { spaId }
  })

  const errors = [spaElevationsError, versionsError, adhocError].filter(
    Boolean
  ) as ApolloError[]

  return {
    spaVersions: versionsData?.spaVersions,
    adhocVersions: adhocData?.adhocVersions,
    spaElevations:
      spaElevationsData?.spaElevations &&
      groupBySpaGroups(spaElevationsData.spaElevations),
    loading: versionsLoading || spaElevationsLoading || adhocLoading,
    errors: errors.length ? errors : null
  }
}
