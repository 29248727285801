import * as React from 'react'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export function EventTableLoading() {
  return (
    <div className='space-y-4'>
      <div className='flex px-4 pt-6 space-x-4'>
        <Skeleton className='w-1/2 h-12 md:h-10' />
        <Skeleton className='w-1/2 h-12 md:h-10' />
      </div>
      <Table>
        <Head>
          <Row>
            <HeadingCell className='w-1/6'>
              <Skeleton className='w-3/4 h-4 my-1' />
            </HeadingCell>
            <HeadingCell className='w-1/6'>
              <Skeleton className='w-3/4 h-4 my-1' />
            </HeadingCell>
            <HeadingCell className='w-1/6'>
              <Skeleton className='w-3/4 h-4 my-1' />
            </HeadingCell>
            <HeadingCell className='w-3/6'>
              <Skeleton className='w-3/4 h-4 my-1' />
            </HeadingCell>
          </Row>
        </Head>
        <Body>
          <TableRow />
          <TableRow />
          <TableRow />
          <TableRow />
          <TableRow />
        </Body>
      </Table>
      <div className='flex justify-end pb-4 m-4'>
        <PaginationIconButton />
        <PaginationIconButton />
        <PaginationIconButton />
        <PaginationIconButton />
        <PaginationIconButton />
      </div>
    </div>
  )
}

function PaginationIconButton() {
  return <Skeleton className='w-6 h-6 m-2' />
}

function TableRow() {
  return (
    <Row>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
    </Row>
  )
}

function TableCell() {
  return (
    <Cell>
      <Skeleton className='w-3/4 h-4 my-1' />
    </Cell>
  )
}
