import { InMemoryCacheConfig } from '@apollo/client'

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        spaElevations: {
          merge: false
        }
      }
    },
    SpaElevation: {
      keyFields: ['group', 'environment', 'spaId']
    }
  }
}
