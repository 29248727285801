import * as React from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  Body,
  Cell,
  Head,
  HeadingCell,
  Row,
  Table
} from '@toasttab/buffet-pui-table'
import { useParams } from 'react-router-dom'
import {
  Column,
  UseFiltersColumnOptions,
  useFilters,
  useTable,
  HeaderProps,
  TableInstance,
  UseFiltersInstanceProps,
  TableState,
  UseFiltersState,
  UseTableRowProps,
  CellProps
} from 'react-table'
import { PathParams, QuerySpaEventsArgs, SpaEvent } from '@local/types'
import { Select } from '@toasttab/buffet-pui-select'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { Pagination, useRowPagination } from '@toasttab/buffet-pui-pagination'
import {
  LookupChecksIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { EventTableLoading } from './EventTableLoading'

const GET_SPA_EVENTS = gql`
  query SpaEvents($spaId: ID!, $maxCount: Int, $maxAge: String) {
    spaEvents(spaId: $spaId, maxCount: $maxCount, maxAge: $maxAge) {
      spaId
      date
      user
      type
      description
      environment
    }
  }
`

/**
 * react-table v7 has some Typescript limitations with plugins that are meant to be fixed in v8
 * Types for plugins (e.g. useFilters, usePagination etc) do not dynamically type the useTable return value, columns and headers.
 * https://github.com/tannerlinsley/react-table/issues/1591#issuecomment-729945218
 */
type TableInstanceWithPlugins<T extends object = SpaEvent> = Omit<
  TableInstance<T>,
  'state'
> & {
  state: TableState<T> & UseFiltersState<T>
}

type ColumnType<T extends object = SpaEvent> = Column<T> &
  Partial<UseFiltersColumnOptions<T>>

type HeaderPropsType<T extends object = SpaEvent> = HeaderProps<T> &
  TableInstanceWithPlugins &
  UseFiltersInstanceProps<T>

const columns: ColumnType[] = [
  {
    id: 'user-filter',
    // @ts-ignore
    Filter: (props: HeaderPropsType) => (
      <SelectColumnFilter
        {...props}
        columnId='user'
        ariaLabel='Filter by user'
        placeholder='User: All'
        options={[
          'All',
          ...new Set(props.data.map((spaEvent) => spaEvent.user))
        ].filter(Boolean)}
      />
    ),
    columns: [
      {
        Header: 'Timestamp',
        accessor: 'date',
        // @ts-ignore
        className: 'whitespace-nowrap',
        // @ts-ignore
        Cell: ({ value }: CellProps<SpaEvent>) =>
          format(new Date(value), Formats.dateTime.medium_with_seconds)
      },
      {
        Header: 'User',
        accessor: 'user',
        // @ts-ignore
        className: 'whitespace-nowrap'
      }
    ]
  },
  {
    id: 'env-filter',
    // @ts-ignore
    Filter: (props: HeaderPropsType) => (
      <SelectColumnFilter
        {...props}
        ariaLabel='Filter by environment'
        placeholder='Environment: All'
        columnId='environment'
        options={[
          'All',
          ...new Set(props.data.map((spaEvent) => spaEvent.environment))
        ].filter(Boolean)}
      />
    ),
    columns: [
      {
        Header: 'Environment',
        accessor: 'environment',
        // @ts-ignore
        className: 'whitespace-nowrap',
        // @ts-ignore
        filter: (
          rows: UseTableRowProps<SpaEvent>[],
          _: unknown,
          filterValue: string | null
        ) =>
          !filterValue
            ? rows
            : rows.filter((r) => r.original.environment === filterValue)
      },
      {
        Header: 'Details',
        accessor: 'description',
        // @ts-ignore
        className: 'w-1/3'
      }
    ]
  }
]

type SpaEventsResponse = {
  spaEvents: SpaEvent[]
}

const initialTableData: SpaEvent[] = []

export const EventTable = () => {
  const { repo: spaId = '' } = useParams<PathParams>()
  const { data, loading, error } = useQuery<
    SpaEventsResponse,
    QuerySpaEventsArgs
  >(GET_SPA_EVENTS, {
    variables: {
      spaId
    }
  })

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data ? data.spaEvents : initialTableData
      },
      useFilters
    ) as TableInstanceWithPlugins

  const {
    currentPageData,
    totalPageCount: _,
    ...paginationProps
  } = useRowPagination({
    rows,
    pageSize: 10
  })

  if (loading) {
    return <EventTableLoading />
  }

  if (error) {
    return (
      <div className='w-full px-4 py-8 m-auto mb-6 lg:w-1/3'>
        <EmptyState
          icon={<WarningOutlineIcon accessibility='decorative' />}
          title='Uh oh, something went wrong'
        >
          There was an issue displaying the SPA events.
        </EmptyState>
      </div>
    )
  }

  if (!data!.spaEvents.length) {
    return (
      <div className='w-full px-8 py-8 m-auto mb-6 lg:w-1/3'>
        <EmptyState
          icon={<LookupChecksIcon accessibility='decorative' />}
          title='No results found'
        >
          New events for {spaId} will appear here.
        </EmptyState>
      </div>
    )
  }

  const [filters, ...tableHeaderGroups] = headerGroups

  return (
    <div className='space-y-4'>
      <div className='flex px-4 pt-6 gap-2 flex-col sm:flex-row'>
        {filters.headers.map((column) => {
          return (
            <div key={column.id} className='w-full sm:w-1/2'>
              {column.render('Filter')}
            </div>
          )
        })}
      </div>
      <div className='overflow-x-auto'>
        <Table {...getTableProps()} aria-label='Event table'>
          <Head>
            {tableHeaderGroups.map((headerGroup) => {
              return (
                <Row
                  style={{ borderBottom: 0 }}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => {
                    const header = column.render('Header')
                    return (
                      <HeadingCell
                        // @ts-ignore
                        className={column.className}
                        {...column.getHeaderProps()}
                      >
                        {header}
                      </HeadingCell>
                    )
                  })}
                </Row>
              )
            })}
          </Head>
          <Body {...getTableBodyProps()}>
            {currentPageData.map((row) => {
              prepareRow(row)
              return (
                <Row {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Cell {...cell.getCellProps()}>{cell.render('Cell')}</Cell>
                  ))}
                </Row>
              )
            })}
          </Body>
        </Table>
      </div>
      <Pagination {...paginationProps} className='flex justify-end pb-4 m-4' />
    </div>
  )
}

type SelectColumnHeaderFilterProps = HeaderProps<SpaEvent> &
  TableInstanceWithPlugins &
  UseFiltersInstanceProps<SpaEvent> & {
    columnId: string
    placeholder: string
    options: string[]
    ariaLabel: string
  }

function SelectColumnFilter({
  ariaLabel,
  setFilter,
  options,
  columnId,
  state: { filters },
  placeholder
}: SelectColumnHeaderFilterProps) {
  return (
    <Select
      aria-label={ariaLabel}
      value={filters.find((filter) => filter.id === columnId)?.value}
      onChange={(value) => setFilter(columnId, value === 'All' ? null : value)}
      options={options}
      placeholder={placeholder}
    />
  )
}
