import { CardContainer } from '@toasttab/buffet-pui-card'
import * as React from 'react'

import { EventTable } from '../EventTable'

export function EventHistory() {
  return (
    <div>
      <h2 className='px-4 mb-2 type-headline-4 md:px-0'>Event history</h2>
      <CardContainer noPadding>
        <EventTable />
      </CardContainer>
    </div>
  )
}
