export function formatVersion(version: string) {
  if (!version.startsWith('adhoc:')) {
    return version
  }
  const v = version.split('adhoc:')[1]
  // This represents about 1/3 of a year's worth of seconds
  // There is no chance of collision within that time frame
  // unless two adhocs built at exactly the same second
  // of each other
  return `adhoc:${v.substring(v.length - 10, v.length - 3)}`
}
